import React from 'react'

import styles from './index.module.scss'

const Mainview = ({ className, children }) => {
	const classNames = styles.mainview + (className ? ` ${className}` : ``)

	return <div className={classNames}>{children}</div>
}

export default Mainview
