import React from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'
import Mainview from 'src/components/mainview/index'
import Title from 'src/components/title/index'
import Inner from 'src/components/inner/index'
import Image from 'src/components/image/index'
// import Link from 'src/components/link/index'
import CampaignButton from 'src/components/campaign/button'
import AdmissionButton from 'src/components/admission/button'
import ReadyToGo from 'src/components/readytogo/index'

import styles from './index.module.scss'

const IatfPage = () => {
	return (
		<div className={styles.iatf}>
			<SEO title="I AM THE FIT" />
			<Header hasBack={false} />
			<Mainview className={styles.mainview}>
				<div className={styles.mainimage}>
					<Inner className={styles.title}>
						<h1>
							<img className={styles.img1} src="/img/iatf/i_am_the_fit.svg" alt="I AM THE FIT" />
							<img
								className={styles.img2}
								src="/img/iatf/for_personal_training.svg"
								alt="FOR PERSONAL TRAINING."
							/>
						</h1>
					</Inner>
				</div>
			</Mainview>
			<div id="about" className={styles.about}>
				<Inner>
					<div className={styles.aboutText}>
						<p>
							TOGETHER! BODY MAKE. <br />I AM THE FITは、あなたと一緒にボディメイクを通じて
							<br />
							ワークアウトの喜びを
							<wbr />
							わかち合いたいと考えています。
						</p>
					</div>
					<div className={styles.aboutButtons}>
						<AdmissionButton className={styles.forAdmission} />
						<CampaignButton className={styles.forCampaign} />
					</div>
				</Inner>
			</div>
			<section id="features" className={styles.features}>
				<ol className={styles.featuresList}>
					<li>
						<div className={styles.featuresImage}>
							<div className={styles.placer}>
								<Image src="/iatf/features01.jpg" alt="" minWidth={0} />
							</div>
						</div>
						<div className={styles.featuresBlock}>
							<div>
								<h3>Features of I AM THE FIT 01</h3>
								<div>
									<strong>無理なく続けられる料金設定</strong>
									<p>
										パーソナルトレーニングは料金が高く、継続が難しいと言う声を良く耳にします。そこで、我々は継続できるプランと継続しやすい料金をご用意致しました。一緒にトレーニングを継続し、身体の変化を楽しみましょう。
									</p>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div className={styles.featuresImage}>
							<div className={styles.placer}>
								<Image src="/iatf/features02.jpg" alt="" minWidth={0} />
							</div>
						</div>
						<div className={styles.featuresBlock}>
							<div>
								<h3>Features of I AM THE FIT 02</h3>
								<div>
									<strong>圧倒的な通いやすさ</strong>
									<p>
										駅から近く手ぶらや仕事帰りでも通いやすい環境を実現。毎日の習慣、日常のなかにトレーニングを追加してほしい。そんな思いでサポート致します。
									</p>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div className={styles.featuresImage}>
							<div className={styles.placer}>
								<Image src="/iatf/features03.jpg" alt="" minWidth={0} />
							</div>
						</div>
						<div className={styles.featuresBlock}>
							<div>
								<h3>Features of I AM THE FIT 03</h3>
								<div>
									<strong>個々に合わせた目標メニューの作成</strong>
									<p>
										目的や目標は人それぞれ違ってきます。専門知識を持ったトレーナーがお客様の目的、目標に合わせて丁寧にメニューを作成。マンツーマンの指導で一緒に進めて参ります。その日の体調に合わせたメニューのカスタマイズ等々、お客様に寄り添ったトレーニングを実施いたします。
									</p>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div className={styles.featuresImage}>
							<div className={styles.placer}>
								<Image src="/iatf/features04.jpg" alt="" minWidth={0} />
							</div>
						</div>
						<div className={styles.featuresBlock}>
							<div>
								<h3>Features of I AM THE FIT 04</h3>
								<div>
									<strong>信頼できるトレーナーによる丁寧な指導</strong>
									<p>
										当店スタッフは自身もトレーニング愛好家なので経験豊富。身体の構造からメンタルケアまで様々な知識と技術を持ち合わせております。お客様の身体に合わせて安全、丁寧、効率的なトレーニングの継続と、モチベーション継続をの全力でサポートいたします。
									</p>
								</div>
							</div>
						</div>
					</li>
				</ol>
			</section>
			<section id="price" className={styles.price}>
				<div>
					<Title h2="PRICE" />
					<Inner>
						<table>
							<thead>
								<tr>
									<th>プラン</th>
									<th>時間</th>
									<th>回数/有効期限</th>
									<th>月額利用料</th>
								</tr>
							</thead>
							<tbody>
								{/* <tr>
									<td>I AM THE FIT 月額プラン / 月会費に追加オプションで受付中</td>
									<td className={styles.time}>
										<span>30</span>分
									</td>
									<td className={styles.available}>
										<span>4回</span> / 1ヶ月
									</td>
									<td className={styles.charge}>
										<span>
											¥10,000<small>（税込 ¥11,000）</small>/ 月
										</span>
									</td>
								</tr> */}
								<tr>
									<td>I AM THE FIT/1 チケット / 店舗にて受付中</td>
									<td className={styles.time}>
										<span>60</span>分
									</td>
									<td className={styles.available}>
										<span>1回</span>
									</td>
									<td className={styles.charge}>
										¥7,000<small>（税込 ¥7,700）</small>/ 月
									</td>
								</tr>
								<tr>
									<td>I AM THE FIT 4チケット / 店舗にて受付中</td>
									<td className={styles.time}>
										<span>60</span>分
									</td>
									<td className={styles.available}>
										<span>4回</span> / 3ヶ月
									</td>
									<td className={styles.charge}>
										<span>
											¥26,600<small>（税込 ¥29,260）</small>/ 月
										</span>
									</td>
								</tr>
								<tr>
									<td>I AM THE FIT 8チケット / 店舗にて受付中</td>
									<td className={styles.time}>
										<span>60</span>分
									</td>
									<td className={styles.available}>
										<span>8回</span> / 6ヶ月
									</td>
									<td className={styles.charge}>
										<span>
											¥50,400<small>（税込 ¥55,440）</small>/ 月
										</span>
									</td>
								</tr>
							</tbody>
						</table>
						<div className={styles.caption}>
							<p>※ 非会員様はビジター料金となり、¥8,000（税込¥8,800）となります。</p>
						</div>
					</Inner>
				</div>
			</section>

			{/*<section id="coach" className={styles.coach}>
				<div>
					<Title
						h2="COACH"
						sub={
							<div className={styles.coachSubText}>
								WE ARE THE
								FIT各店でスタッフとしても勤務しているので、店舗の事、トレーニングに関する質問など、コミュニケーションの取りやすい環境をご用意。
								<br />
								ワークアウトの喜びを丁寧にお伝えして参ります。
							</div>
						}
					/>
					<Inner>
						<ul className={styles.gyms}>
							<li>
								<div className={styles.coachImage}>
									<div className={styles.placer}>
										<Image src="/iatf/coach_toritsudai.jpg" alt="" minWidth={0} />
									</div>
									<div className={styles.text}>
										<h3>WE ARE THE FIT TORITSUDAI</h3>
									</div>
								</div>
								<ul className={styles.coaches}>
									<li>
										<p>
											<Link to="https://www.instagram.com/wearethefit__ippei/">
												<span className={styles.coachName}>松田 一平</span>
												<span className={styles.coachInstagram}>wearethefit__ippei</span>
											</Link>
										</p>
									</li>
									<li>
										<p>
											<Link to="https://www.instagram.com/wearethefit_hiro/">
												<span className={styles.coachName}>早野 博久</span>
												<span className={styles.coachInstagram}>wearethefit_hiro</span>
											</Link>
										</p>
									</li>
								</ul>
							</li>
							<li>
								<div className={styles.coachImage}>
									<div className={styles.placer}>
										<Image src="/iatf/coach_ekoda.jpg" alt="" minWidth={0} />
									</div>
									<div className={styles.text}>
										<h3>WE ARE THE FIT EKODA</h3>
									</div>
								</div>
								<ul className={styles.coaches}>
									<li>
										<p>
											<span className={styles.coachName}>川瀬 晃広</span>
										</p>
									</li>
									<li>
										<p>
											<Link to="https://www.instagram.com/wearethefit_riku/">
												<span className={styles.coachName}>稲川 陸</span>
												<span className={styles.coachInstagram}>wearethefit_riku</span>
											</Link>
										</p>
									</li>
									<li>
										<p>
											<Link to="https://www.instagram.com/wearethefit_kao/">
												<span className={styles.coachName}>藤田 佳緒</span>
												<span className={styles.coachInstagram}>wearethefit_kao</span>
											</Link>
										</p>
									</li>
									<li>
										<p>
											<Link to="https://www.instagram.com/wearethefit_taki/">
												<span className={styles.coachName}>大滝 晃平</span>
												<span className={styles.coachInstagram}>wearethefit_taki</span>
											</Link>
										</p>
									</li>
								</ul>
							</li>
						</ul>
					</Inner>
				</div>
			</section>
			<section id="reservation" className={styles.reservation}>
				<div>
					<Title h2="RESERVATION" />
					<Inner>
						<ul>
							<li>
								<Link to="https://lin.ee/tyzFOe6">
									<div className={styles.placer}>
										<Image src="/top/gym_toritsudai.jpg" minWidth={0} />
									</div>
									<div className={styles.bottom}>
										<div className={styles.qrCode}>
											<img src="/img/iatf/qr_toritsudai.png" alt="QRコード" />
										</div>
										<div className={styles.text}>
											<h3>WE ARE THE FIT TORITSUDAI</h3>
											<span>
												<em>LINE</em>からトレーナーを予約する
											</span>
										</div>
									</div>
								</Link>
							</li>
							<li>
								<Link to="https://lin.ee/9tnMkcj">
									<div className={styles.placer}>
										<Image src="/top/gym_ekoda.jpg" minWidth={0} />
									</div>
									<div className={styles.bottom}>
										<div className={styles.qrCode}>
											<img src="/img/iatf/qr_ekoda.png" alt="QRコード" />
										</div>
										<div className={styles.text}>
											<h3>WE ARE THE FIT EKODA</h3>
											<span>
												<em>LINE</em>からトレーナーを予約する
											</span>
										</div>
									</div>
								</Link>
							</li>
						</ul>
					</Inner>
				</div>
          </section>*/}
			<ReadyToGo />
		</div>
	)
}

export default IatfPage
